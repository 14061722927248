import './hero.css';
import React from 'react';
import { Link } from 'gatsby';


function Hero(){
    return(
        <div className="hero_wrapper">
            <div className="hero_bg_green">
                <div class="container mx-auto px-2">
                    <h1 className="text-4xl  md:text-5xl lg:text-6xl xl:text-7xl font-extrabold text-center text-white">Vi gör allt inom plåt och smide</h1>
                    <p className="text-lg md:text-2xl m-7 md:w-3/4 md:m-auto md:my-10 text-center text-white" >Danas Plåt och Smide startades 1966 och har över 55 års erfarenhet. Vi hjälper dig med både stora och små projekt, som både privatperson och företag.</p>
                    <div className="w-100 flex justify-center">
                        <Link to="/kontakt" className="bg-red-700 shadow-xl text-white text-md md:text-xl p-4 rounded hover:bg-red-800">Kontakta Oss</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Hero;