import React from "react";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import Hero from '../components/hero/hero';
import Feature from '../components/feature';
import Helmet from 'react-helmet';

import byggnadssmide_img from '../images/byggnadssmide/byggnadssmide_xs.jpg';
import tillverkning_img from '../images/tillverkning/tillverkning_xs.jpg';
import montering_img from '../images/montering/montering_xs.jpg';
import logistik_img from '../images/logistik/logistik_xs.jpg';
import termisk_img from '../images/termisk/termisk_xs.jpg';
import blastring_img from '../images/blastring/blastring_xs.jpg';
import malning_img from '../images/malning/malning_xs.jpg';
import lager_img from '../images/lager/lager_xs.jpg';
import uthyrning_img from '../images/about-top_xs.jpg';
import certifiering_img from '../images/certifiering.svg';

import certifikat_en_1090_1 from '../images/certifikat/certifikat-en-1090-1.svg';
import certifikat_9001_14001 from '../images/certifikat/certifikat-9001-14001.svg';
import beslut_iso_3834_3 from '../images/certifikat/beslut-iso-3834-3.svg';
import beslut_9001_14001 from '../images/certifikat/beslut-9001-14001.svg';
import certifikat_iso_3834_3 from '../images/certifikat/certifikat-iso-3834-3.svg';
import hardness_testing from '../images/certifikat/hardness_testing.svg';

import favicon from '../images/favicon.ico';


const Home = ()=> {

  return(
    <Layout>
      <Helmet>
        <title>Danas Plåt &amp; Smide AB</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="plåtslageri och smidesverkstad som tar uppdrag i Södertälje, Stockholm, Mälarområdet, Södermanland och Uppland." />
        <meta name="keywords" content="plåt, smide, Blästring, Rostskyddsmålning, Termisk Sprutning" />
        <meta property="og:title" content="Danas Plåt och Smide AB - Södertälje" />
        <meta property="og:tyope" content="website" />
        <meta property="og:discription" content="plåtslageri och smidesverkstad som tar uppdrag i Södertälje, Stockholm, Mälarområdet, Södermanland och Uppland." />
        <meta property="og:image" content=" " />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:url" content="https://danasplat.se/" />
        <link rel="cannonical" href="https://danasplat.se/" />
      </Helmet>

      <MyNavbar active_page="hem" />
      <Hero />


      { /*****  Våra tjänster  - START  ******/}
      <section className="py-32">
        <div className="container mx-auto">
          <div className="w-full md:w-5/6 mx-auto flex flex-wrap">
            <h1 className="w-full text-3xl  md:text-4xl font-extrabold text-center mb-32 text-green-700">Våra Tjänster</h1>
            <Feature 
              img_url={byggnadssmide_img}
              link_to="/byggnadssmide"
              img_alt="Byggnadssmide"
              title="Byggnadssmide"

            />
            <Feature 
              img_url={tillverkning_img}
              link_to="/svetsarbete"
              img_alt="Svetsarbete"
              title="Svetsarbete"
            />
            <Feature 
              img_url={montering_img}
              link_to="/montering"
              img_alt="Montering och rivning"
              title="Montering &amp; Rivning"
            />
            <Feature 
              img_url={logistik_img}
              link_to="/logistik"
              img_alt="Transport och logistik"
              title="Transport &amp; Logistik"
            />
            <Feature 
              img_url={termisk_img}
              link_to="/tillverkning"
              img_alt="tillverkning"
              title="Tillverkning"
            />
            <Feature 
              img_url={blastring_img}
              link_to="/blastring"
              img_alt="Isblästring / kolsyreblästring"
              title="Isblästring / Kolsyreblästring"
            />
            <Feature 
              img_url={malning_img}
              link_to="/malning"
              img_alt="Rostskyddsmålning och Industrimålning"
              title="Rostskyddsmålning &amp; Industrimålning"
            />
            <Feature 
              img_url={lager_img}
              link_to="/lager"
              img_alt="Lagerförvaring"
              title="Lagerförvaring"
            />
            <Feature 
              img_url={uthyrning_img}
              link_to="/uthyrning"
              img_alt="Uthyrning och Bemanning"
              title="Uthyrning &amp; Bemanning"
            />
          </div>
        </div>
      </section>
      { /*****  Våra tjänster  - END  ******/}




      { /*****  Miljövänlig sektion - START  ******/}
      <section className="bg-gray-100">
        <div className="container mx-auto body-font">
          <div className=" w-full md:w-5/6 mx-auto flex px-5 py-32 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-32 md:mb-0">
              <img className="object-cover object-center rounded" alt="svetscertifiering" src={certifiering_img}/>
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-bold text-green-700">Miljövänligt Plåtslageri och Smidesverkstad</h1>
              <p className="mb-8 font-extralight leading-relaxed lg:text-xl text-gray-600">Vi ska värna om miljön och arbetar med miljöaspekter i våra arbetsprocesser för att ha så lite påverkan som möjligt på miljön. Vi erbjuder sakkunskap, säkerhet och pålitlighet och erbjuder varor och tjänster där vi tar största möjliga miljöhänsyn på kort- och lång sikt. Vi informerar, involverar och fortlöpande utbildar anställda och underentreprenörer i vårt miljöarbete. I och med vårt ständigt uppdaterade lagregister håller vi oss uppdaterade på de författningar som gäller.</p>
              <div className="flex justify-center">
                <Link to="/kontakt" className="inline-flex shadow-xl text-white bg-red-700 border-0 p-4 px-6 focus:outline-none hover:bg-red-800 rounded text-lg">Kontakta Oss</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      { /*****  Miljövänlig sektion  - END  ******/}


      { /*****  Våra Certifieringar  - START  ******/}
      <section className="py-32">
        <div className="container mx-auto">
          <div className="w-full md:w-5/6 mx-auto flex flex-wrap">
            <h1 className="w-full text-3xl  md:text-4xl font-extrabold text-center mb-28 text-green-700">Våra Certifieringar</h1>
            <Feature 
              img_url={certifikat_en_1090_1}
              link_to="/certifikat/certifikat_en_1090_1"
              img_alt="certifikat en 1090-1"
              title="Certifikat EN 1090-1"
            />
            <Feature 
              img_url={certifikat_9001_14001}
              link_to="/certifikat/certifikat_9001_14001"
              img_alt="Certifikat 9001-14001"
              title="Certifikat 9001-14001"
            />
            <Feature 
              img_url={beslut_iso_3834_3}
              link_to="/certifikat/beslut_iso_3834_3"
              img_alt="beslut iso 3834-3"
              title="Beslut ISO 3834-3"
            />
            <Feature 
              img_url={beslut_9001_14001}
              link_to="/certifikat/beslut_9001_14001"
              img_alt="beslut 9001-14001"
              title="Beslut 9001-14001"
            />
            <Feature 
              img_url={certifikat_iso_3834_3}
              link_to="/certifikat/certifikat_iso_3834_3"
              img_alt="certifikat iso 3834-3"
              title="Certifikat ISO 3834-3"
            />
            <Feature 
              img_url={hardness_testing}
              link_to="/certifikat/hardness_testing"
              img_alt="Brinell hardness testing"
              title="Brinell hardness testing"
            />
          </div>
        </div>
      </section>
      { /*****  Våra Certifieringar  - END  ******/}

      { /*****  Karta  - START  ******/}
      <section className="container mx-auto py-32 ">
        <div className="w-full md:w-5/6 mx-auto flex flex-col px-5">
          <div className="flex flex-col text-center w-full mb-20">
                <h1 className="sm:text-3xl text-2xl font-extrabold title-font mb-4 text-green-700">Danas Plåt &amp; Smide AB</h1>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Vi är ett plåtslageri &amp; smidesverkstad som har kontor och verkstad beläget på Vasa Gård i Södertälje. Vi tar till oss uppdrag i Mälarområdet, Södermanland och Uppland.</p>
          </div>
          <div className="w-full h-80 mx-auto ">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4087.097624263198!2d17.56370705078735!3d59.19012206651032!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe4e9cd9f9842e78!2sDanas%20Pl%C3%A5t%20%26%20Smide%20AB!5e0!3m2!1ssv!2sse!4v1625764467129!5m2!1ssv!2sse" 
              className="w-full h-full rounded"
              allowFullScreen="" 
              title="Danas Plat och Smide AB"
              />
          </div>

        </div>
      </section>
      { /*****  Karta  - END  ******/}
      
      <Footer />
    </Layout>
  );
}
export default Home;